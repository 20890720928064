
import {onMounted, ref} from "vue";
// @ts-ignore
import PersonalFormService, {StoreResponse} from "@/services/employee/PersonalFormService";
// @ts-ignore
import EmployeeService, {Stage, StageResponse} from "@/services/employee/EmployeeServise";
// @ts-ignore
import router from "@/router";

export default {
  setup() {
    const formValues = ref({
      fullName: "",
      inn: "",
      phone: "",
      email: "",
    })
    const formErrors = ref({})

    async function checkStage() {
      const response = await EmployeeService.stage();
      const data = response.data.data as StageResponse

      if (data.next != Stage.Personal) {
        await router.push({name: `employee/${data.next}`})
      }
    }

    async function submitForm() {
      try {
        const response = await PersonalFormService.store(formValues.value);
        await router.push({name: `employee/${response.data.data.next}`})
      } catch (err) {
        const data = err.response.data as StoreResponse;
        if (data.errors) {
          formErrors.value = data.errors;
        }
      }
    }

    onMounted(async () => {
      await checkStage();
    })

    return {
      formValues,
      formErrors,
      submitForm,
    }
  }
}
