<template>
  <h1>Заявка на подключение самозанятости</h1>
  <h3>Персональные данные</h3>
  <form @submit.prevent class="uk-form-stacked">
    <div class="uk-margin">
      <label class="uk-form-label">ФИО</label>
      <input class="uk-input" type="text" placeholder="Достоевский Федор Михайлович" v-model="formValues.fullName">
      <div class="" v-if="formErrors.fullName">
        <span class="uk-text-danger" v-for="error in formErrors.fullName">{{ error }}</span>
      </div>
    </div>
    <h3>Данные налоговой службы</h3>
    <div class="uk-margin">
      <label class="uk-form-label">ИНН</label>
      <input class="uk-input" type="text" placeholder="027714145906" v-model="formValues.inn">
      <div class="" v-if="formErrors.inn">
        <span class="uk-text-danger" v-for="error in formErrors.inn">{{ error }}</span>
      </div>
    </div>

    <div class="uk-alert-success" uk-alert v-if="innValidationstatus == 1">
      <p>Статус самозанятого подтвержден.</p>
    </div>

    <div class="uk-alert-danger" uk-alert v-if="innValidationstatus == 0">
      <p>Статус самозанятого не подтвержден</p>
      <p><a href="https://www.tinkoff.ru/help/business-self-employment/self-employed/work/start/" target="_blank">Регистрация
        в приложении Тинькофф</a> / <a href="https://npd.nalog.ru/#start" target="_blank">Регистрация в приложении Мой
        налог</a></p>
    </div>


    <h3>Данные для связи</h3>
    <div class="uk-margin">
      <label class="uk-form-label">Почта</label>
      <input class="uk-input" type="text" placeholder="dostoevsky@gmail.com" v-model="formValues.email">
      <div class="" v-if="formErrors.email">
        <span class="uk-text-danger" v-for="error in formErrors.email">{{ error }}</span>
      </div>
    </div>
    <div class="uk-margin">
      <label class="uk-form-label">Мобильный телефон</label>
      <input class="uk-input" type="text" placeholder="89995539381" v-model="formValues.phone">
      <div class="" v-if="formErrors.phone">
        <span class="uk-text-danger" v-for="error in formErrors.phone">{{ error }}</span>
      </div>
    </div>


    <button class="uk-button uk-button-primary" @click="submitForm">Сохранить</button>
  </form>
</template>

<script lang="ts">
import {onMounted, ref} from "vue";
// @ts-ignore
import PersonalFormService, {StoreResponse} from "@/services/employee/PersonalFormService";
// @ts-ignore
import EmployeeService, {Stage, StageResponse} from "@/services/employee/EmployeeServise";
// @ts-ignore
import router from "@/router";

export default {
  setup() {
    const formValues = ref({
      fullName: "",
      inn: "",
      phone: "",
      email: "",
    })
    const formErrors = ref({})

    async function checkStage() {
      const response = await EmployeeService.stage();
      const data = response.data.data as StageResponse

      if (data.next != Stage.Personal) {
        await router.push({name: `employee/${data.next}`})
      }
    }

    async function submitForm() {
      try {
        const response = await PersonalFormService.store(formValues.value);
        await router.push({name: `employee/${response.data.data.next}`})
      } catch (err) {
        const data = err.response.data as StoreResponse;
        if (data.errors) {
          formErrors.value = data.errors;
        }
      }
    }

    onMounted(async () => {
      await checkStage();
    })

    return {
      formValues,
      formErrors,
      submitForm,
    }
  }
}
</script>