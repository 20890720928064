import axios, {AxiosPromise} from "axios";

export enum Stage {
    Personal = 'personal',
    Passport = 'passport',
    Address = 'address',
    Bank = 'bank',
    Confirmation = 'confirmation',
}

export interface StageResponse {
    last: Stage
    next: Stage
}

class EmployeeService {
    stage(): Promise<AxiosPromise> {
        return axios.get(`/employee/stage`);
    }
}

export default new EmployeeService();