import axios, {AxiosPromise} from "axios";
// @ts-ignore
import PersonalForm, {PersonalFormErrors} from "@/types/PersonalForm";
// @ts-ignore
import {Stage} from "@/services/employee/EmployeeServise";
// @ts-ignore
import Employee from "@/types/models/Employee";

export interface StoreResponse {
    success: boolean
    nextStage: Stage|undefined
    errors: PersonalFormErrors|undefined
}

export interface GetResponse {
    success: boolean
    data: Employee
}

class PersonalFormService {
    get(id: number): Promise<AxiosPromise> {
        return axios.get(`/employee/personal`);
    }

    store(data: PersonalForm): Promise<AxiosPromise> {
        return axios.post("employee/personal", data);
    }
}

export default new PersonalFormService();